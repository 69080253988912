<script setup>
import { ref } from "vue";
import Party from "../parts/Party.vue";

const props = defineProps({
  path: String,
  model: Object,
  user: Object,
  args: String,
});
const args = decodeURIComponent(props.args);
const parties = JSON.parse(args);
</script>

<template>
  <div className="PartySchedule">
    <div class="section-background"></div>
    <div class="segment width_pc">
      <div class="segment-background"></div>
      <div class="content">
        <h1 class="content-title">Party schedule</h1>
        <div class="PartySchedule-flex">
          <div v-for="party in parties" className="PartySchedule-party">
            <Party :party="party" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.PartySchedule-section-background {
  position: absolute;
  // height: 100%;
  // width: 100%;
  z-index: -10;
}
.segment {
  background: linear-gradient(#e5e5e5 15%, #efb2ae);
  box-shadow: 0px 20px 0px 0px #458ea7;
  border-radius: 40px;
  margin-bottom: 20px;
}
.width_pc {
  width: 100%;
  max-width: 1280px;
}
.segment-background {
  position: absolute;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
}
.content {
  padding-top: 24px;
  padding-bottom: 48px;
}
.content-title {
  margin: 0px 0px 40px 0px !important;
}
.PartySchedule-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.PartySchedule-party {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  align-items: center;
  position: relative;

  .party-box {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    // flex: 1 0 auto; /* fix IE11 */
  }
  @media screen and (max-width: 799px) {
    width: 100%;
  }
  @media screen and (min-width: 800px) {
    width: calc(100% / 2);
  }
  @media screen and (min-width: 1024px) {
    width: calc(100% / 3);
  }
}
</style>
