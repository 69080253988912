import { createRouter,createWebHistory } from 'vue-router';
// import Menu from "./components/parts/Menu"
// import Home from "./pages/Home.vue";
// import Page1 from "./pages/Page1.vue";
// import Page2 from "./pages/Page2.vue";

const routes = [
    // { path: '/', name: 'home' , component: Menu },
    // { path: '/Page1', name: 'page1', component: Menu },
    // { path: '/Page2', name: 'page2', component: Page2 },
]

const router = createRouter({
  history: createWebHistory(), // HTML5 History モード
  routes,
})

const locations = {
  'party': '/#party',
  'service': '/#service',
  'statistics': '/#statistics',
  'what_joycon': '/#what_joycon',
  'achievements': '/#achievements',
  'media': '/#media',
  'faq': '/#faq'
}


export const location_move = (target) =>{
  let ele = document.getElementById(target);
  if(ele){
    let ele = document.getElementById(target);
    let header = document.getElementById("vue-App");
    let top = ele.offsetTop || 0 ; // - header.offsetHeight || 0;
    window.scrollTo({top:top, behavior: 'smooth'});
    document.body.scrollTo({top:top, behavior: 'smooth'});
  }else{
    if(target in locations){
      location.href = locations[target];
    }else{
      location.href = target;
    }
  }
}

export default router;
