<script setup>
import { ref, onMounted } from "vue";
import { useToast as prime_toast } from "primevue/usetoast";
import Menu from "../parts/Menu.vue";
import Service from "./Service.vue";

const props = defineProps({
  path: String,
  model: Object,
  user: Object,
  args: String,
});

const dialog = { header: "", message: "" };

let toast; // = prime_toast();

const displayModal = ref(false);
const openModal = () => {
  displayModal.value = true;
};
const closeModal = () => {
  displayModal.value = false;
};

const useToast = function (event) {
  let detail = event.detail;
  let payload = detail.payload || {};

  switch (detail.type) {
    case "toast":
      toast.add({
        severity: payload.severity || "success",
        summary: payload.summary || "",
        detail: payload.detail || "",
        life: payload.life || 3000,
        group: "tr",
      });
      break;
    case "top-right":
      toast.add({
        severity: payload.severity || "success",
        summary: payload.summary || "",
        detail: payload.detail || "",
        life: payload.life || "",
        url: payload.url || "",
        group: "tr",
      });
      break;
    case "bottom-right":
      toast.add({
        severity: payload.severity || "success",
        summary: payload.summary || "",
        detail: payload.detail || "",
        life: payload.life || "",
        group: "br",
      });
      break;
    default:
      break;
  }
};

const useDialog = function (event) {
  let detail = event.detail;
  if (detail.type === "dialog") {
    let payload = detail.payload || {};
    dialog.header = payload.header || "";
    dialog.message = payload.detail;
    openModal();
  }
};

onMounted(() => {
  toast = prime_toast();
  window.removeEventListener("vue", useToast);
  window.removeEventListener("vue", useDialog);
  window.addEventListener("vue", useToast);
  window.addEventListener("vue", useDialog);
});
</script>

<template>
  <div>
    <Menu :user="props.user"></Menu>
    <!-- <div @click="openModal">openModal</div> -->
    <slot></slot>
    <Dialog
      :header="dialog.header"
      v-model:visible="displayModal"
      :style="{}"
      :modal="true"
      className="top_dialog"
    >
      <pre>{{ dialog.message }}</pre>
      <template #footer>
        <Button label="OK" @click="closeModal" />
      </template>
    </Dialog>
    <Toast position="top-right" group="tr" class="today5">
      <template #message="slotProps">
        <a :href="slotProps.message.url">
          <div class="p-toast-message-content">
            <span
              v-if="slotProps.message.severity === 'success'"
              class="p-toast-message-icon pi pi-check"
            ></span>
            <span
              v-if="slotProps.message.severity === 'info'"
              class="p-toast-message-icon pi pi-info-circle"
            ></span>
            <span
              v-if="slotProps.message.severity === 'warn'"
              class="p-toast-message-icon pi pi-exclamation-triangle"
            ></span>
            <div class="p-toast-message-text">
              <span class="p-toast-summary">{{
                slotProps.message.summary
              }}</span>
              <div
                class="p-toast-detail"
                v-html="slotProps.message.detail"
              ></div>
            </div>
          </div>
        </a>
      </template>
    </Toast>
    <Toast position="bottom-right" group="br">
      <template #message="slotProps">
        <div class="p-toast-message-content">
          <span
            v-if="slotProps.message.severity === 'success'"
            class="p-toast-message-icon pi pi-check"
          ></span>
          <span
            v-if="slotProps.message.severity === 'info'"
            class="p-toast-message-icon pi pi-info-circle"
          ></span>
          <span
            v-if="slotProps.message.severity === 'warn'"
            class="p-toast-message-icon pi pi-exclamation-triangle"
          ></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
            <div class="p-toast-detail" v-html="slotProps.message.detail"></div>
          </div>
        </div>
      </template>
    </Toast>
    <Toast position="bottom-center" group="bc">
      <template #message="slotProps">
        <div class="flex flex-column">
          <div class="text-center">
            <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
            <i class="pi pi-info-circle" style="font-size: 3rem"></i>
            <h4>{{ slotProps.message.summary }}</h4>
            <p>{{ slotProps.message.detail }}</p>
          </div>
        </div>
      </template>
    </Toast>
  </div>
</template>

<style>
.p-toast {
  opacity: 0.95 !important;
  max-width: 90%;
  z-index: 1000;
}
.p-toast-top-right {
  top: 120px;
}
.p-toast .p-toast-message .p-toast-message-content {
  width: 100%;
  padding: 0.5rem !important;
}

.top_dialog {
  border: 1px solid black;
  background: #f6f7f6;
  border-radius: 6px;
  padding: 1rem;
  .p-dialog-header {
    .p-dialog-header-close-icon {
      margin: 10px;
    }
  }
  .p-dialog-content {
    padding: 1rem 0;
    pre {
      white-space: pre-line;
    }
  }
  .p-dialog-footer {
    text-align: right;

    Button {
      font-family: "Roboto";
      font-weight: 700;
      color: #ffffff;
      border: 1px solid #1e1e1e;
      background: #1e1e1e;
      border-radius: 6px;
      &:hover {
        cursor: pointer;
        text-decoration-line: unset;
        color: #ffffff !important;
        border: 1px solid #c6372f;
        background: #c6372f !important;
      }
    }
  }
}

.p-toast {
  a {
    color: #000000 !important;
    font-family: "Roboto";
    font-weight: 700;
    &:hover {
      cursor: pointer;
      color: #c6372f !important;
      text-decoration-line: underline;
    }
  }
}

.today5 {
  display: flex;
  justify-content: space-between;
  a {
    text-decoration-line: none !important;
  }
}
</style>
