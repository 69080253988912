/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import { createApp,createElementVNode,h } from 'vue';
import router from './router';

import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Menubar from 'primevue/menubar';
import MegaMenu from 'primevue/megamenu';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

import ja from "./locale/ja"



import "./themes/saga-blue.css" // テーマ
// import "primevue/resources/themes/saga-blue/theme.css" // テーマ
// import "primevue/resources/themes/saga-green/theme.css" // テーマ
// import "primevue/resources/themes/saga-orange/theme.css" // テーマ
// import "primevue/resources/themes/saga-purple/theme.css" // テーマ
import "primevue/resources/primevue.min.css" // ベース
import "primeicons/primeicons.css" // アイコン
// import "primeflex/primeflex.css" // primeflex

const app_mount = (app,selector) =>{
  app.use(PrimeVue, {ripple: true, locale: ja }); // localeにja.tsをセット
  app.use(router);
  app.use(ToastService);

  app.component('Dialog', Dialog);
  app.component('Button', Button);
  app.component('Menubar', Menubar);
  app.component('MegaMenu', MegaMenu);
  app.component('Toast', Toast);

  if(document.querySelector(selector)){
    app.mount(selector);
  }
}

//----------------------------------------------------------------------------------------------------
// 現状では仕組み上、複数の Vue App インスタンスを作成する形となっています
//----------------------------------------------------------------------------------------------------
const v_map = [
  {app:null,target:'#vue-App',binding:require('./components/bindings/App').default},
  // {app:null,target:'#vue-Menu',binding:require('./components/bindings/Menu').default},
  {app:null,target:'#vue-PartySchedule',binding:require('./components/bindings/PartySchedule').default},
  {app:null,target:'#vue-Service',binding:require('./components/bindings/Service').default},
]


const mounter = () =>{
  window._app = {};
  v_map.forEach(v => {
    let page_state = decodeURIComponent(window._page_state);
    let props = JSON.parse(page_state) ;
    let target = document.querySelector(v.target);

    if(target){
      props["args"] = target.getAttribute("args");
      if(!v.app){
        v.app = createApp({
          render: () => h(v.binding, props)
        })
        app_mount(v.app,v.target);
        window._app[v.target] = v.app;
      }
    }
  })
}

// document.addEventListener('DOMContentLoaded', () => {
//   mounter();
// })

// https://qiita.com/morrr/items/54f4be21032a45fd4fe9
document.addEventListener("DOMContentLoaded", (e) => {
  mounter();
  // console.log(location.href,"DOMContentLoaded");
  // console.log(e);
})

document.addEventListener("turbolinks:before-visit", (e) => {
  // console.log(location.href,"turbolinks:before-visit");
  // console.log(e);
})
document.addEventListener("turbolinks:request-start", (e) => {
  // console.log(location.href,"turbolinks:request-start");
  // console.log(e);
})
document.addEventListener("turbolinks:visit", (e) => {
  // console.log(location.href,"turbolinks:visit");
  // console.log(e);
})
document.addEventListener("turbolinks:before-cache", (e) => {
  // console.log(location.href,"turbolinks:before-cache");
  // console.log(e);
})
document.addEventListener("turbolinks:load", (e) => {
  mounter();
  // console.log(location.href,"turbolinks:load");
  // console.log(e);
})



document.addEventListener("turbolinks:before-render", (e) => {
  // console.log(location.href,"turbolinks:before-render");
  // console.log(e);
})
document.addEventListener("turbolinks:render", (e) => {
  // console.log(location.href,"turbolinks:render");
  // console.log(e);
})
document.addEventListener("turbolinks:click", (e) => {
  // console.log(location.href,"turbolinks:click");
  // console.log(e);
})


document.addEventListener("turbolinks:request-createElementVNode	", (e) => {
  // console.log(location.href,"turbolinks:request-createElementVNode");
})



window.send_vue_message = (message) =>{
  var event = new CustomEvent("vue", {
    detail: message
  });
  window.dispatchEvent(event);
}

window.send_vue_message_onload = (message) => {
  let done = false;
  let func = () => {
    if(!done && window.send_vue_message){
      done = true;
      window.send_vue_message(message);
      window.removeEventListener('turbolinks:load', func);
    }
  }
  window.addEventListener('turbolinks:load', func);
}

export default v_map;
