export function formatDate (date, format) {
  let w = date.getDay(); // 曜日を取得（数値）
  // 曜日を数値から文字列に変換するための配列
  let week = ["日", "月", "火", "水", "木", "金", "土"];
  format = format.replace(/yyyy/g, date.getFullYear());
  format = format.replace(/yy/g, ('0' + date.getFullYear()).slice(-2));
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
  format = format.replace(/aaa/g, week[w]);
  return format;
};
